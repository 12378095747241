<template>
  <div class="jiqun">
    <el-button type="text" icon="el-icon-upload" @click="OpenJiqun">{{
      $t("选择")
    }}</el-button>
    <div v-for="(itm, inx) in content" :key="inx">
      <span class="marginRight">{{ itm.name }}</span>
      <el-button
        class="buttonXz"
        type="primary"
        icon="el-icon-close"
        size="mini"
        circle
        @click="del(inx, content)"></el-button>
    </div>
    <el-dialog
      class="jiqun-dialog"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :destroy-on-close="true"
      :fullscreen="fullscreen">
      <div slot="title" class="d-flex justify-content-between">
        <div class="title">{{ $t("选择云文件") }}</div>
        <div class="header-btn">
          <el-button type="text" size="mini" class="full-btn" @click="fullFn">
            <i class="el-icon-full-screen"></i>
          </el-button>
        </div>
      </div>

      <el-tabs type="card" class="tabsCard">
        <el-tab-pane :label="$t('公共数据')"></el-tab-pane>
      </el-tabs>
      <div class="searchBox">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            class="jumpBread"
            v-for="(item, index) in breadList"
            :key="index"
            @click.native="jumpOpen1(item.to, index)"
            >{{ item.name }}</el-breadcrumb-item
          >
        </el-breadcrumb>
        <!-- <el-input placeholder="请输入内容" v-model="searchValue" class="input">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input> -->
      </div>
      <search-table
        ref="searchTable"
        class="searchTable"
        :pageShow="false"
        :tableData="tableData"
        @rowClick="rowClick"
        :tableHeight="tableHeight">
        <template #shift>
          <el-table-column type="selection" width="55"> </el-table-column>
        </template>
      </search-table>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose" size="mini">{{
          $t("关闭")
        }}</el-button>
        <el-button type="primary" @click="subQ" size="mini">{{
          $t("确定")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getProjectFilePath } from "@/api/list";
export default {
  name: "jiqunDialog",
  props: {
    //传值
    content: {
      type: [Array, String],
      default: () => null,
    },
    prop: {
      type: String,
      default: "",
    },
    pjcode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      jixu: false,
      searchValue: "",
      breadList: [{ name: "..", to: "" }],
      tableData: {
        header: [
          { prop: "name", label: "文件名" },
          { prop: "type", label: "文件类型" },
          { prop: "size", label: "文件大小" },
        ],
        table: [],
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      dataarr: "",
      dialogVisible: false,
      fullscreen: false,
      tableHeight: 300,
    };
  },
  components: {
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
    content: {
      handler(val, oldVal) {
        this.dataarr = val;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log("jiqunDialog");
    const parentMethods = Object.keys(this.$listeners); //有没有父组件传递过来的自定义事件
    console.log(parentMethods, "555");
    if (!parentMethods.includes("OpenJiqun")) {
      this.jixu = true;
    }
  },
  activated: function () {
    // 狗子触发顺序最后时执行，当再次进入（前进或者后退）时，只触发activated
  },
  methods: {
    // 组件的方法
    subQ() {
      console.log("123");
      if (this.dataarr.length == 0) {
        this.$alert(this.$t("请选择文件/文件夹"), this.$t("提示"), {
          confirmButtonText: this.$t("确定"),
          callback: (action) => {},
        });
        return;
      }

      this.$emit("update:content", this.dataarr);
      this.handleClose();
    },
    bianli(json, num) {
      var arr = [];
      for (var key in json) {
        console.log(typeof json[key]);
        if (typeof json[key] != "object") {
          var li = json[key].split(",");
          arr.push({
            name: li[0],
            size: li[1],
            type: li[2],
            ceng: 0,
            id: arr.length,
            num: json[key],
          });
        } else {
          arr.push({
            name: json[key][0],
            children: json[key][1],
            size: "-",
            type: "-",
            ceng: 1,
            id: arr.length,
            num: json[key],
          });
        }
      }

      return arr;
    },
    del(index, type) {
      console.log(index, type);
      type.splice(index, 1);
      this.$emit("update:content", type);
    },
    rowClick(data) {
      console.log(data);
      if (data.event == "selectionChange") {
        if (data.row[0].type == "-") {
          this.tableData.table = this.bianli(data.row[0].children);
          this.jumpOpen(data.row[0]);
        } else {
          console.log(data.row);
          var arr = [];
          var dataarr = [];
          var row = data.row;
          var selectioned1 = data.row.map((item) => {
            return item;
          });
          var name = this.breadList
            .map((item) => {
              return item.name;
            })
            .join("/");
          arr = selectioned1.map((item) => {
            return {
              name: name + "/" + item.name,
              label: "选择文件",
              id: item.id,
            };
          });
          for (var b = 0; b < arr.length; b++) {
            if (
              JSON.stringify(dataarr).indexOf(JSON.stringify(arr[b])) == -1 &&
              row.type != "-"
            ) {
              dataarr.push(arr[b]);
            }
          }
          this.dataarr = dataarr;
        }
      }
    },
    jumpOpen(row) {
      console.log(row);
      this.breadList.push({ name: row.name, to: row.num });
      this.tableData.table = this.bianli(row.children);
      this.huitian();
    },
    jumpOpen1(row, index) {
      console.log(row, index, this.breadList);
      this.breadList.splice(index + 1, this.breadList.length - 1);
      var json = "";
      if (row == "") {
        json = this.tablejson;
      } else {
        json = row[1];
      }
      this.tableData.table = this.bianli(json);
      this.huitian();
    },
    handleClose(done) {
      this.dialogVisible = false;
      this.breadList = [{ name: "..", to: "" }];
      this.searchValue = "";
      //  this.ruleForm[this.uploadType] =[]
    },
    OpenJiqun(event, callback) {
      // this.$emit("update:data","123")
      console.log(event, callback);
      //   return;
      if (this.changeFileShow && !callback) {
        callback = this.changeFileShow;
      }
      if (!callback) {
        this.$emit("OpenJiqun", event);
      } else {
        console.log("7888");
        // return
        var data = {
          search: this.prop,
          pjcode: this.pjcode,
        };

        getProjectFilePath(data).then((response) => {
          console.log(response);
          if (response.code == 1) {
            if (typeof response.data == "object") {
              var json = response.data;
              //   console.log(json,"111");
              this.tablejson = json;

              this.tableData.table = this.bianli(json);
            }
            this.huitian();
            this.dialogVisible = true;
            //
          } else {
            // this.$message.error(response.data.msg);
            this.$message({
              showClose: false,
              message: response.msg,
              type: "error",
              offset: "100",
            });
          }
        });
      }
    },
    huitian() {
      this.$nextTick(() => {
        this.tableData.table.map((item, index) => {
          if (typeof this.content != "string") {
            this.content.map((it) => {
              var newtable = it.name.split("/");
              if (newtable.includes(item.name)) {
                this.$refs.searchTable.$refs.table.toggleRowSelection(
                  this.tableData.table[index],
                  true
                );
              }
            });
          }
        });
      });
    },
    fullFn() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        this.tableHeight = 550;
      } else {
        this.tableHeight = 300;
      }
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style lang="scss" scoped>
.jiqun-dialog {
  ::v-deep .el-dialog__body {
    padding: 0 pxttrem(10);
  }
  .header-btn {
    .full-btn {
      font-size: pxttrem(18);
      color: #666;
      &:hover {
        color: #3385ff;
      }
    }
  }
  .searchBox {
    display: flex;
    .input {
      width: pxttrem(300);
    }
  }
}
</style>
