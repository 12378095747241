import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Cookies from 'js-cookie' //cookies
Vue.prototype.$Cookies = Cookies; //全局使用cookie
import './icons' // 引入icon
/* elementui */
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/icon.css'
Vue.use(Element, {
  size: 'medium', // set element-ui default size
})

Vue.config.productionTip = false

import { globalVariable } from '@/global/Variable'
Vue.prototype.$globalVariable = globalVariable; //全局变量

import * as methodCookies from "@/utils/methodCookies";
Vue.prototype.$methodCookies = methodCookies; //定义全局cookie方法

import { deepCopy } from "@/utils/validate";
Vue.prototype.$deepCopy = deepCopy; //深拷贝

import BigFile from "@/components/bigUpload/index.vue";//大文件上传
Vue.component("BigFile", BigFile);

import Pagination from '@/components/Pagination/index.vue'//分页
Vue.component("Pagination", Pagination);
import Bread from '@/components/Bread/index.vue'//面包屑
Vue.component("Bread", Bread);
import diagBox from '@/components/diagBox/index.vue'//示例文件弹窗组件
Vue.component("diagBox", diagBox);
import searchTable from '@/components/searchTable/searchTable.vue'//筛选表格
Vue.component("searchTable", searchTable);
import diyAllSelect from '@/components/diyAllSelect/index.vue'//全选下拉
Vue.component("diyAllSelect", diyAllSelect);
import diyTable from '@/components/diyTable/diyTable.vue'//无prop表格
Vue.component("diyTable", diyTable);
import jiqunDialog from '@/components/jiqunDialog/index.vue'//集群弹窗组件
Vue.component("jiqunDialog", jiqunDialog);
import mixin from '@/utils/mixin.js'
Vue.prototype.$mixin = mixin; //全局混入

import 'amfe-flexible'//自适应
import '@/utils/rem.js'
import "@/log";//引入log监听文件
// import vConsole from '@/utils/vconsole' //移动端显示console
// import consoleFu from '@/utils/consoleLog' //移动端显示console方法
import Notification from '@/components/Notification';
Vue.prototype.$Notification = Notification
import '@/styles/globalCss.scss'//全局css样式
import '@/utils/globalUpdate'

import removeAria from "./moudle/removeAria";
//全局注册
Vue.directive("removeAria", removeAria);

import i18n from '@/i18n/i18n'


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

document.addEventListener('keydown', function (event) {//去除表单回车刷新页面
  // console.log(event)
  // @ts-ignore
  if (event.target && event.target.form === null) return; // 只对表单元素生效
  // debuger
  if (event.code !== "Enter") return; // 判断按下的键是否为 Enter 键
  // @ts-ignore
  if (event.target.type === "textarea" && event.target.form !== null) return // 忽略表单textarea元素

  event.preventDefault(); // 取消默认行为（即不会刷新页面）
});
document.addEventListener("click", function (event) {
  if (event.target.className == "el-radio__original") {
    //   //添加属性
    let aria = document.querySelectorAll(".el-radio__original");
    aria.forEach(item => {
      item.removeAttribute("aria-hidden");
    });
    // event.target.parentNode.parentNode.setAttribute("inert", "true");
    // event.target.parentNode.parentNode.setAttribute("aria-hidden", "false");
    // event.target.parentNode.setAttribute("aria-hidden", "true");
  }
})
// var lastClickTime=0,count=0
// document.addEventListener('click', function (event) {//控制台显示
//   // console.log(event.target.className)
//   let target = event.target;
//   if (target.nodeName == "SPAN"|| target.nodeName == 'I') {
//     target = event.target.parentNode;
//   }
//   if(target.nodeName=="BUTTON"){
//     target.blur();
//   }
//   if(event.target.className.indexOf("vc-")!=-1){
//     count=0
//     return
//   }
//   const nowTime = new Date().getTime();
//   if (nowTime - lastClickTime < 1000) {
//     count++;
//   } else {
//     count = 0;
//   }
//   lastClickTime = nowTime;
//   if (count >= 10) {
//     let vconDom = document.getElementById('__vconsole');
//     consoleFu.toggleClass(vconDom, 'show')
//     count = 0;

//   }
// });
