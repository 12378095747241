import Vue from 'vue'
import VueRouter from 'vue-router'
import routesList from './routers.js'
import store from '@/store'
import { getCookies } from '@/utils/methodCookies.js'
import { MessageBox } from 'element-ui';
import i18n from '@/i18n/i18n'
Vue.use(VueRouter)

const routes = routesList
// [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   // {
//   //   path: '/about',
//   //   name: 'about',
//   //   // route level code-splitting
//   //   // this generates a separate chunk (about.[hash].js) for this route
//   //   // which is lazy-loaded when the route is visited.
//   //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   // }
// ]
const createRouter = () => new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})
const router = createRouter()
router.afterEach((to, from) => {
  store.commit('log/setMonitoring', {
    title: "切换页面",
    formPage: from.path,
    toPage: to.path,
  })
})
// 忽略重复导航的错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
function deleteAllCookies() {//删除所有cookie
  const cookies = document.cookie.split("; ");
  for (let cookie of cookies) {
    const equalSign = cookie.indexOf("=");
    const cookieName = equalSign > -1 ? cookie.substr(0, equalSign) : cookie;
    document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  }
}
function clearLocalStorage() {//删除所有localStorage
  window.localStorage.clear();
  localStorage.clear();
}
function clearAll() {
  deleteAllCookies();
  clearLocalStorage();
}

router.beforeEach((to, from, next) => {
  window._axiosPromiseArr.forEach((el, index) => {
    console.log(el)
    el.cancel() // 路由跳转之前，中止请求
    //  重置 window._axiosPromiseArr
    delete window._axiosPromiseArr[index]
  })
  if (to.matched.length === 0) {
    // 如果目标路由不存在，跳转到 404 页面
    next({ path: "/404", replace: true }); // 使用 replace 方法
  } else {
    // 如果缓存里面有 Token，重置路由并继续
    if (getCookies("Admin-Token") && JSON.stringify(store.getters["userList"]) !== "{}") {
      // 判断缓存里面是否有 Token
      //   resetRouter()
      if (to.meta && to.meta.noCache) {
        store.commit("pageRouter/addCachedPage", to.path);
      }
      next()
    } else {
      store.commit("user/setUserList", {});
      //   //   console.log("111")
      //   //   //   // removeCookies()
      //   //   //   // router.push({ path: "/"  })
      if (to.path === "/login" || to.path === "/404" || to.path === "/403" || to.path === "/500" || to.path === "/home") {
        console.log("ccc")
        next();
      }
      else {
        MessageBox.confirm(i18n.t("请先登录后再进行查看"), i18n.t("提示"), {
          confirmButtonText: i18n.t("确定"),
          cancelButtonText: i18n.t("取消"),
          type: "warning",
          closeOnClickModal: false,
          closeOnPressEscape: false,
        })
          .then(() => {
            clearAll();
            next('/login')
          })
          .catch(() => { });

      }
    }
  }
})
export function resetRouter() {
  const newRouter = createRouter()
  var routerL = []
  for (var i = 0; i < newRouter.options.routes.length; i++) {//循环权限
    // if(newRouter.options.routes[i].role){//判断权限
    //   console.log(newRouter.options.routes[i].role.includes(Number(getCookies('Admin-id'))),getCookies('Admin-id'))
    //   if(newRouter.options.routes[i].role.includes(Number(getCookies('Admin-id')))){
    //     routerL.push(newRouter.options.routes[i])
    //   }
    // }else{
    routerL.push(newRouter.options.routes[i])
    // }

  }
  newRouter.options.routes = routerL
  router.matcher = newRouter.matcher // reset router
}
export default router
