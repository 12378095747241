<template>
  <el-popover
    placement="bottom"
    width="auto"
    v-model="visible"
    popper-class="searchDropDown">
    <template v-for="(itm, key) in searchList">
      <el-input
        v-model="form[itm.tiaojian]"
        :placeholder="$t('请输入搜索内容')"
        size="mini"
        clearable
        v-if="itm.type == 'input'"></el-input>
      <el-divider :content-position="itm.position" v-if="itm.type == 'line'">{{
        itm.text
      }}</el-divider>
      <el-select
        v-model="form[itm.tiaojian]"
        :placeholder="$t('请选择')"
        size="mini"
        filterable
        clearable
        style="width: 100%"
        v-if="itm.type == 'select'">
        <el-option
          :label="item"
          :value="item"
          v-for="(item, index) in itm.option"
          :key="index"></el-option>
      </el-select>
      <el-radio-group
        v-model="form[itm.tiaojian]"
        v-removeAria
        v-if="itm.type == 'radio'">
        <el-radio
          :label="item"
          v-for="(item, index) in itm.option"
          :key="index"></el-radio>
      </el-radio-group>
      <el-checkbox-group
        class="searchCheckBox"
        v-model="form[itm.tiaojian]"
        v-if="itm.type == 'checkbox'">
        <el-checkbox
          :label="typeof item === 'object' ? item.value : item"
          class="checkBox"
          v-for="(item, index) in itm.option"
          :key="index"
          >{{ typeof item === "object" ? item.label : item }}</el-checkbox
        >
      </el-checkbox-group>
      <div v-if="itm.type == 'searchCheckBox'">
        <el-input
          v-model="searchCheck"
          :placeholder="$t('请输入搜索内容')"
          size="mini"
          clearable></el-input>
        <el-checkbox-group v-model="form[itm.tiaojian]" class="searchCheckBox">
          <el-checkbox
            :label="typeof item === 'object' ? item.value : item"
            class="checkBox"
            v-for="(item, index) in searchCheckBox(itm.option)"
            :key="index"
            >{{ typeof item === "object" ? item.label : item }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </template>
    <div class="popover-btn">
      <el-button type="primary" size="mini" @click="search('search')">{{
        $t("筛选")
      }}</el-button>
      <el-button type="warning" size="mini" @click="reset">{{
        $t("重置")
      }}</el-button>
      <el-button size="mini" type="danger" @click="close">{{
        $t("取消")
      }}</el-button>
    </div>
    <el-button slot="reference" type="text">
      <svg-icon
        :icon-class="qihuan(keyValue) ? 'shaixuan' : 'down'" /> </el-button
    ><!-- el-icon-finished -->
  </el-popover>
</template>

<script>
export default {
  name: "searchDropDown",
  props: {
    //传值
    keyValue: {
      type: String,
      default: "",
    },
    searchList: {
      type: Array,
      default: () => [
        { value: "", type: "input", tiaojian: "input" },
        {
          position: "center", //left / right / center
          text: "",
          type: "line",
        },
        {
          option: ["选项1", "选项2", "选项3"],
          value: "",
          type: "select",
          tiaojian: "dd",
        },
        {
          option: ["选项1", "选项2", "选项3"],
          value: "",
          type: "radio",
          tiaojian: "aa",
        },
        {
          option: ["选项1", "选项2", "选项3"],
          value: "",
          type: "checkbox",
          tiaojian: "ff",
        },
      ],
    },
  },
  data() {
    return {
      visible: false,
      form: {},
      searchCheck: "",
    };
  },
  components: {
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
    searchList: {
      handler(newVal, oldVal) {
        newVal.map((itme) => {
          if (itme.type !== "line") {
            // if (item.type == "searchCheckBox" || item.type == "checkbox") {
            //   this.$set(this.$data.form, itme.tiaojian, []);
            // } else {
            this.$set(this.$data.form, itme.tiaojian, itme.value);
            // }
            // this.form[itme.type] = ''
          }
        });
        console.log(this.form);
      },
      deep: true,
      immediate: true, // 确保创建watch时form的值就被监听
    },
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    qihuan() {
      return function (keyValue) {
        var boolean = false;
        console.log(this.$parent.$parent.$parent);
        if (this.$parent.$parent.$parent.serachForm.hasOwnProperty(keyValue)) {
          boolean = true;
        } else {
          boolean = false;
        }
        return boolean;
      };
    },

    searchCheckBox() {
      return function (option) {
        var arr = option.filter((item) => {
          if (typeof item === "object") {
            return (
              item.label
                .toLowerCase()
                .indexOf(this.searchCheck.toLowerCase()) != -1
            );
          } else {
            return (
              item.toLowerCase().indexOf(this.searchCheck.toLowerCase()) != -1
            );
          }
        });
        // console.log(arr);
        return arr;
      };
    },
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log("searchDropDown");
  },
  activated: function () {
    // 狗子触发顺序最后时执行，当再次进入（前进或者后退）时，只触发activated
  },
  methods: {
    // 组件的方法
    close() {
      this.visible = false;
    },
    search(type = "search") {
      console.log(this.form);
      this.$emit("search-change", {
        serach: this.form,
        keyValue: this.keyValue,
        type: type,
      });
      this.close();
    },
    reset() {
      var leixing = {};
      this.searchList.map((item) => {
        if (item.type !== "line") {
          leixing[item.tiaojian] = item.type;
        }
      });
      for (var key in this.form) {
        if (leixing[key] == "searchCheckBox" || leixing[key] == "checkbox") {
          this.form[key] = [];
        } else {
          this.form[key] = "";
        }
      }
      this.search("reset");
      this.searchCheck = "";
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style lang="scss" scoped>
.searchDropDown {
  .el-divider--horizontal {
    margin: pxttrem(10) 0 !important;
  }
}
.searchCheckBox {
  max-height: pxttrem(200);
  overflow-y: auto;
  .checkBox {
    display: block;
    padding-top: pxttrem(10);
  }
}
.popover-btn {
  text-align: right;
  margin: 0;
  margin-top: pxttrem(10);
}
</style>
