var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',[_c('div',[_c('el-button',{attrs:{"size":"mini","type":"primary","icon":"el-icon-upload"},on:{"click":function($event){return _vm.upload($event)}}},[_vm._v(_vm._s(_vm.$t("点击上传")))]),_vm._t("default")],2),_vm._l((_vm.percentData),function(item,key){return (JSON.stringify(_vm.percentData) != '{}')?_c('div',{key:key,staticClass:"FileList"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.upflag[key]))]),_c('div',{staticClass:"StatusBox"},[(_vm.percentData[key] != 100)?_c('el-progress',{staticClass:"jindutiao",attrs:{"type":"circle","percentage":_vm.percentData[key]}}):_c('i',{staticClass:"el-icon-upload-success el-icon-circle-check"}),_c('i',{staticClass:"el-icon-circle-close el-icon-upload-close",on:{"click":function($event){return _vm.yichu(key)}}})],1)]):_vm._e()})],2),_c('el-upload',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"uploadfile",staticClass:"upload-demo",attrs:{"action":"","multiple":"","accept":_vm.accept,"auto-upload":false,"on-change":(file) => {
          return _vm.changeFile(file);
        },"limit":_vm.limit,"on-exceed":(files, fileList) => {
          return _vm.handleExceed(files, fileList);
        },"before-remove":(files, fileList) => {
          return _vm.handleRmove(files, fileList);
        },"file-list":_vm.form['filelist']}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }