// src/i18n.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import enLocale from "./elementUI-En";
import zhLocale from "./elementUI-Ch";
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'zh', // 设置默认语言
    messages: {
        zh: { ...require('./zh.json'), ...zhLocale },
        en: { ...require('./en.json'), ...enLocale },
        // 添加其他语言文件
    },
    silentTranslationWarn: true, // 关闭翻译警告
});
ElementLocale.i18n((key, value) => i18n.t(key, value));
export default i18n
