function setRem() {
    const browserWidth = window.innerWidth; // 浏览器窗口如何显示的宽度
    const documentWidth = document.documentElement.clientWidth; // 文档的宽度
    // const zoomLevel = window.devicePixelRatio;
    const browserWidth1 = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const zoomLevel = Number((window.outerWidth / browserWidth1).toFixed(2));

    console.log(zoomLevel)
    const screenWidth = 192 / zoomLevel
    const scale = screenWidth / 1.92
    const scale1 = screenWidth / 18
    const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
    const htmlDowm = document.getElementsByTagName('html')[0]
    const bodyDowm = document.getElementsByTagName('body')[0]
    htmlDowm.style.fontSize = htmlWidth / scale + 'px'
    bodyDowm.style.fontSize = htmlWidth / scale + 'px'
    // bodyDowm.style.fontSize = htmlWidth / scale1 + 'px'
    // bodyDowm.style = ''
}
setRem()
window.onresize = function () {
    setRem()
}