
import axios from 'axios'
import store from '@/store'
import { MessageBox, Message, Loading } from 'element-ui'
import { getCookies, removeCookies, setCookies } from './methodCookies';

import router from "@/router";
let loadingInstance = null
// create an axios instance
var baseURL = process.env.VUE_APP_BASE_API;
window._axiosPromiseArr = []; // 全局聲明 (變數掛在window下)
const service = axios.create({
  baseURL: baseURL, // 请求地址
  withCredentials: true, // 跨域请求时发送cookie
  timeout: 0, // 设置超时时间
  params: {}, // 无论请求为何种类型，在params中的属性都会以key=value的格式在urlzhong拼
  headers: {
    "Access-Control-Allow-Headers": "Authorization, Content-Type, Depth, User-Agent, X-File-Size, X-Requested-With, X-Requested-By, If-Modified-Since, X-File-Name, X-File-Type, Cache-Control, Origin",
    "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Origin": "*",

    "Access-Control-Expose-Headers": "Authorization"
  },

  diyLoading: false // 是否显示自定义loading
})
// request interceptor 添加请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做某事
    if (config.url != "/log") {
      store.commit("log/setMonitoring", {
        title: "发请求",
        data: JSON.stringify(config)
      })
      if (config.diyLoading) {
        loadingInstance = Loading.service({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }
    }

    const toKen = getCookies('Admin-Token');
    if (toKen) {
      config.headers.Authorization = toKen
    }
    // 每个请求都会 加入
    config.cancelToken = new axios.CancelToken((cancel) => {
      //  这个我习惯放再window 里面，也有配置再vuex里面的 感觉太麻烦了
      window._axiosPromiseArr.push({ cancel });
    });
    return config
  },
  error => {
    // do something with request error
    console.log(error, 11) // for debug
    return Promise.reject(error)
  }
)

// response interceptor 成功 拦截器
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (response.config.url != "/log") {
      store.commit("log/setMonitoring", {
        title: "接收请求",
        data: JSON.stringify(res)
      })
      if (response.config.diyLoading) { loadingInstance.close(); }
    }


    // console.log(response)


    // if the custom code is not 20000, it is judged as an error.
    if (res.code === 1000) {
      // 1000: token过期;
      if (res.code === 1000) {
        // to re-login
        removeCookies()
        window._axiosPromiseArr.forEach((el, index) => {
          console.log(el);
          el.cancel(); // 路由跳转之前，中止请求
          //  重置 window._axiosPromiseArr
          delete window._axiosPromiseArr[index];
        });
        store.commit("user/setUserList", {}); //添加用户信息store
        MessageBox.confirm('您已注销，您可以取消以留在此页面，或重新登录', '确认注销', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          router.push({ path: "/login" });
        })
      }

      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      if (res.token) {
        setCookies("Admin-Token", res.token)
      }

      return res
    }
  },
  error => {

    console.log('err' + error) // for debug
    if (error.config.url != "/log") {
      store.commit("log/setMonitoring", {
        title: "接收请求",
        data: JSON.stringify(error)
      })
      if (error.config.diyLoading) { loadingInstance.close(); }

    }

    // Message({
    //   message: error.msg,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
