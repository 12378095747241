import Layout from '@/layout/index'

/**
 * 注意：子菜单仅在路由子菜单时显示。长度>=1
 *
 * hidden: true                   如果设置为true，则该项将不会显示在侧边栏中（默认为false）
 * alwaysShow: true               如果设置为true，将始终显示根菜单
 *                                如果未设置alwaysShow，则当项目在路线中有多个子项时，
 *                                它将变为嵌套模式，否则不显示根菜单
 * redirect: noRedirect           重定向：noRedirect如果设置noRedirect，则面包屑中不会重定向
 * name:'router-name'             名称：'router-name'名称由＜keep-alive＞使用（必须设置！！！）
 * role:[]                        权限  如果需要区分权限必写，并且为数组格式
 * meta : {
    title: 'title'               侧边栏和面包屑中显示的名称（推荐设置）
    icon: 'svg-name'/'el-icon-x' 侧边栏中显示的图标
    noCache: true                如果设置为true，则不会缓存页面（默认为false）
    breadcrumb: false            如果设置为false，则该项将隐藏在面包屑中（默认为true）
    activeMenu: '/example/list'  如果设置路径，侧边栏将突出显示您设置的路径
  }
 */

var fullrouter = [
    {/* 错误页 */
        path: '/404',
        component: () => import('@/views/error-page/404'),
        hidden: true,
        meta: { title: '404', icon: 'dashboard' },
    },
    {/* 错误页 */
        path: '/403',
        component: () => import('@/views/error-page/403'),
        hidden: true,
        meta: { title: '403', icon: 'dashboard' },
    },
    {/* 错误页 */
        path: '/500',
        component: () => import('@/views/error-page/500'),
        hidden: true,
        meta: { title: '500', icon: 'dashboard' },
    },
    {/* 登录页 */
        path: '/login',
        component: () => import('@/views/login-page/login'),
        hidden: true,
        meta: { title: 'login', icon: 'dashboard' },
    },
]
export default fullrouter