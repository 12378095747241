<template>
  <div class="app-container">
    <div class="select-column-Box">
      <el-popover
        placement="bottom"
        width="auto"
        v-model="visible"
        v-if="checkList.length != 0">
        <el-checkbox-group v-model="checkList" class="select-column-list">
          <el-checkbox
            :label="item"
            v-for="(item, index) in columnList"
            :key="index"
            class="column-item"
            >{{
              JSON.stringify(zidian) != "{}" ? $t(zidian[item]) : $t(item)
            }}</el-checkbox
          >
        </el-checkbox-group>
        <el-button slot="reference"><i class="el-icon-menu"></i></el-button>
      </el-popover>
      <slot></slot>
    </div>
    <el-table
      :data="tableData.table"
      ref="table"
      class="tableBox"
      style="width: 100%"
      border
      :height="(tableHeight / 192) * 10 + 'rem'"
      @select-all="handleSelectAll"
      @select="handleSelectionChange"
      @cell-click="rowClick">
      <slot name="shift" v-if="tableData.table.length != 0"></slot>
      <el-table-column
        align="center"
        v-for="(item, index) in tableData.header"
        :key="index"
        :prop="item.prop"
        :fixed="item.fixed ? item.fixed : false"
        :width="item.width ? item.width : jisuanWidth(item)"
        v-if="showColumn(item.prop)">
        <template slot="header" slot-scope="scope">
          {{ $t(item.label)
          }}<search-drop-down
            v-if="item.searchList"
            :ref="'searchDropDown_' + item.prop"
            :keyValue="item.prop"
            :searchList="item.searchList"
            @search-change="searchChange"></search-drop-down>
        </template>

        <template slot-scope="scope">
          <slot name="cell" :prop="item.prop" :row="scope.row"
            ><div class="cellBox" :title="scope.row[item.prop]">
              {{ scope.row[item.prop] }}
              <span
                :class="{ copy: true, copyNoShow: !scope.row[item.prop] }"
                @click="copyText(scope.row[item.prop])"
                ><i class="el-icon-document-copy"></i
              ></span></div
          ></slot>
        </template>
      </el-table-column>
      <slot name="caozuo" v-if="tableData.table.length != 0"></slot>
    </el-table>
    <pagination
      v-if="pageShow"
      class="paginationBox"
      :total="tableData.total"
      :limit="tableData.pageSize"
      :page="tableData.currentPage"
      @page-change="pageChange"></pagination>
  </div>
</template>

<script>
import SearchDropDown from "./searchDropDown.vue";
export default {
  name: "searchTable",
  props: {
    //传值
    rowxuanze: {
      type: Array,
      default: () => [],
    },
    columnList: {
      type: Array,
      default: () => [],
    },
    default_header: {
      type: Array,
      default: () => [],
    },
    pageShow: {
      type: Boolean,
      default: true,
    },
    zidian: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tableHeight: {
      type: [Number, String],
      default: 500,
    },

    tableData: {
      type: Object,
      default: () => ({
        header: [
          {
            label: "日期",
            prop: "date",
            searchList: [
              { value: "", type: "input" },
              {
                position: "center", //left / right / center
                text: "",
                type: "line",
              },
              {
                option: ["选项1", "选项2", "选项3"],
                value: "",
                type: "select",
              },
              {
                position: "center", //left / right / center
                text: "",
                type: "line",
              },
              { option: ["选项1", "选项2", "选项3"], value: "", type: "radio" },
              {
                position: "center", //left / right / center
                text: "",
                type: "line",
              },
              {
                option: ["选项1", "选项2", "选项3"],
                value: "",
                type: "checkbox",
              },
              {
                option: ["选项1", "选项2", "选项3"],
                value: "",
                type: "searchCheckBox",
              },
            ],
          },
        ],
        table: [{ date: "2023" }],
        total: 100,
        pageSize: 10,
        currentPage: 10,
      }),
    },
  },
  data() {
    return {
      checkList: [],
      visible: false,
      serachForm: {},
      xuanze: [],
    };
  },
  components: {
    SearchDropDown,
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
    tableData: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        this.$nextTick(() => {
          this.$refs.table.doLayout();
          for (var i = 0; i < this.rowxuanze.length; i++) {
            this.$refs.table.toggleRowSelection(this.rowxuanze[i], true);
          }
        });
      },
      deep: true,
    },
    checkList: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.$refs.table.doLayout();
        });
      },
      deep: true,
    },
    columnList: {
      handler(val, old) {
        console.log(val, old);
        this.checkList = [];
        this.visible = false;
        val.map((item) => {
          if (this.default_header.length != 0) {
            if (this.default_header.includes(item)) {
              this.checkList.push(item);
            }
          } else {
            this.checkList.push(item);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    showColumn() {
      return function (prop) {
        if (this.$refs.table) {
          this.$nextTick(() => {
            this.$refs.table.doLayout();
          });
        }
        if (this.checkList.length == 0) {
          return true;
        } else {
          return this.checkList.includes(prop);
        }
      };
    },
    jisuanWidth() {
      return function (item) {
        console.log(item, "fff");
        var num = 150;
        if (num < flexWidthFunction(item.label)) {
          num = flexWidthFunction(item.label);
        }
        this.tableData.table.map((itm) => {
          if (num < flexWidthFunction(String(itm[item.prop]))) {
            num = flexWidthFunction(String(itm[item.prop]));
          }
        });
        if (this.checkList.length <= 4) {
          return "auto";
        }
        console.log(num, "num");
        if (this.$refs.table) {
          const parentElement = this.$refs.table.$el;
          // 获取父元素的宽度
          var parentWidth = parentElement.offsetWidth; //- 150;
          var length = this.checkList.length;
          // 百分比值（例如50%）
          const percentage = 100 / length;

          // 将百分比换算成像素值
          const pxValue = (percentage / 100) * parentWidth;
          console.log(pxValue, percentage);

          if (num < pxValue) {
            num = pxValue;
          }
        }

        console.log(this.checkList.length);
        function flexWidthFunction(columnContent) {
          let flexWidth = 0;
          for (const char of columnContent) {
            // console.log(char)
            if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
              // 如果是英文字符，为字符分配8个单位宽度
              flexWidth += 8;
            } else if (char >= "\u4e00" && char <= "\u9fa5") {
              // 如果是中文字符，为字符分配15个单位宽度
              flexWidth += 15;
            } else {
              // 其他种类字符，为字符分配8个单位宽度
              flexWidth += 12;
            }
          }
          // if (flexWidth < 150) {
          //   // 设置最小宽度
          //   flexWidth = 150;
          // }
          // if (flexWidth > 250) {
          // // 设置最大宽度
          // flexWidth = 250
          // }
          return flexWidth;
        }
        return num + 50 + "px";
      };
    },
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log("searchTable");
  },
  activated: function () {
    // 狗子触发顺序最后时执行，当再次进入（前进或者后退）时，只触发activated
  },
  methods: {
    // 组件的方法
    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
        console.log("内容已复制到剪贴板");
      } catch (err) {
        console.error("复制失败", err);
      }
    },
    chongzhi() {
      this.serachForm = {};
    },
    handleSelectAll(row) {
      this.$emit("update:rowxuanze", this.tableData.alltable);
      this.$emit("rowClick", { row, event: "selectionAll" });
    },
    handleSelectionChange(row) {
      console.log(row);
      this.$emit("update:rowxuanze", row);
      this.$emit("rowClick", { row, event: "selectionChange" });
    },
    rowClick(row, cell, column, event) {
      this.$refs.table.toggleRowSelection(row);
      var arr = this.rowxuanze.concat(row);
      var uniqueArray = Array.from(
        new Map(arr.map((item) => [JSON.stringify(item), item])).values()
      );
      const index = this.rowxuanze.findIndex((item) => {
        // 检查 item 是否与 target 相等，使用 Object.entries 比较所有键值对
        return Object.entries(row).every(([key, value]) => item[key] === value);
      });
      if (index !== -1) {
        uniqueArray.splice(index, 1);
      }
      this.handleSelectionChange(uniqueArray);
      // this.$emit("rowClick", { row, cell, column, event: "rowClick" });
    },
    searchChange(form) {
      console.log(form);

      this.serachForm[form.keyValue] = form.serach;
      const cleanedData = this.removeEmptyValues(
        this.serachForm[form.keyValue]
      );
      console.log(this.removeEmptyValues(this.serachForm[form.keyValue]));
      if (Object.keys(cleanedData).length !== 0) {
        this.serachForm[form.keyValue].time = new Date().getTime();
      } else {
        delete this.serachForm[form.keyValue];
      }
      const sortedData = this.sortObjectByTimeDesc(this.serachForm);
      var sortList = [];
      sortedData.map((item) => {
        sortList.push(item[0]);
      });
      console.log(sortList, "sortedData");
      sortList.splice(4);
      for (var key in this.serachForm) {
        if (!sortList.includes(key)) {
          for (var i = 0; i < this.$refs["searchDropDown_" + key].length; i++) {
            this.$refs["searchDropDown_" + key][i].reset();
          }
          delete this.serachForm[key];
        }
      }
      this.$emit("search-change", this.serachForm);
    },
    pageChange(val) {
      console.log(val);
      this.$refs.table.$el.scrollIntoView();
      this.$emit("page-change", val);
    },
    sortObjectByTimeDesc(obj) {
      return Object.entries(obj).sort((a, b) => b[1].time - a[1].time);
    },
    isEmpty(value) {
      if (value === null || value === undefined) return true;
      if (typeof value === "string" && value.trim() === "") return true;
      if (Array.isArray(value) && value.length === 0) return true;
      if (typeof value === "object" && Object.keys(value).length === 0)
        return true;
      return false;
    },

    removeEmptyValues(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(([key, value]) => {
          if (typeof value === "object" && value !== null) {
            value = this.removeEmptyValues(value); // 递归处理嵌套对象
          }
          return !this.isEmpty(value);
        })
      );
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style lang="scss" scoped>
.paginationBox {
  // margin-top: pxttrem(10);
  text-align: center;
}
.tableBox {
  ::v-deep .el-table__fixed,
  .el-table__fixed-right {
    height: 100% !important; /* 确保固定行的高度正确 */
  }
}
.select-column-Box {
  text-align: right;
  position: relative;
}
.select-column-list {
  max-height: pxttrem(500);
  overflow: auto;
  .column-item {
    display: block !important;
  }
}
.cellBox {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  position: relative;
}
.cellBox:hover {
  .copy {
    opacity: 1;
  }
}
.copy {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  cursor: pointer;
  color: #409eff;
  opacity: 0;
}
.copyNoShow {
  opacity: 0 !important;
}
</style>
