// import {log} from "@/api/response" //请求后端做记录

const state = {
  userList: {
  },
  // userMenu: {},
};

const mutations = {
  setUserList(state, data) {
    console.log(data, "55555")
    if (!data) {
      state.userList = {};
    } else {
      var json = {
        ...data,
      };
      state.userList = json;
    }

    // log(json).then((res)=>{
    //     console.log(res)
    //   }).catch(err => {
    //       // 报错
    //       console.log(err)
    //     })
  },
  // setUserMenu(state, data) {
  //   if (!data) {
  //     state.userMenu = {};
  //   } else {
  //     var json = {
  //       ...data,
  //     };
  //     state.userMenu = json;
  //   }
  // },
};

const actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
