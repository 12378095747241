// import {log} from "@/api/response" //请求后端做记录

const state = {
  cachedPages: [],
  // userMenu: {},
};

const mutations = {
  addCachedPage(state, pageName) {
    if (!state.cachedPages.includes(pageName)) {
      state.cachedPages.push(pageName);
    }
  },
  removeCachedPage(state, pageName) {
    const index = state.cachedPages.indexOf(pageName);
    if (index !== -1) {
      state.cachedPages.splice(index, 1);
    }
  }
}

const actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
