import Notification from '@/components/Notification';
import i18n from '@/i18n/i18n'
var worker = new Worker('./update.worker.js')
// console.log(window.location.origin)
const url = process.env.VUE_APP_BASE_URL != "/" ? process.env.VUE_APP_BASE_URL + "/" : window.location.origin + "/"
// console.log(process.env.VUE_APP_BASE_URL)
var worker = new Worker(url + 'update.worker.js')
const packageJson = require('../..//package.json');
console.log(packageJson); // 输出package.json内容
// var worker = new Worker('/update.worker.js')
let data = { pageVisibility: false }
worker.postMessage(data)
worker.onmessage = function (event) {
    console.log(event.data, 'worker主线程1')

    if (event.data?.isUpdate) {
        Notification({
            title: i18n.t('页面更新提示'),
            message: i18n.t("更新版本：V") + packageJson.version + '<br>' + i18n.t('网页内容有更新，请按Ctrl+R或F5刷新页面'),
            duration: 0,
            type: 'diyjinggao',
            btn: { success: { label: i18n.t("更新"), hidden: false }, close: { label: i18n.t("关闭"), hidden: false } }
        }).then(() => {
            window.location.reload(true)
        }).catch(() => {
            window.location.reload(true)
        })
        worker.terminate()
    }
}

worker.onerror = function (event) {
    console.log(event)
    // console.error(event.filename + ':' + event.message)
    //如果发生错误,立即终止代码
    worker.terminate()
}

// 监听页面是否隐藏
document.addEventListener('visibilitychange', () => {
    console.log(document.hidden, '能监听到啥-html')
    let hidden = document.hidden
    data.pageVisibility = hidden
})