import Layout from '@/layout/index'

/**
 * 注意：子菜单仅在路由子菜单时显示。长度>=1
 *
 * hidden: true                   如果设置为true，则该项将不会显示在侧边栏中（默认为false）
 * alwaysShow: true               如果设置为true，将始终显示根菜单
 *                                如果未设置alwaysShow，则当项目在路线中有多个子项时，
 *                                它将变为嵌套模式，否则不显示根菜单
 * redirect: noRedirect           重定向：noRedirect如果设置noRedirect，则面包屑中不会重定向
 * name:'router-name'             名称：'router-name'名称由＜keep-alive＞使用（必须设置！！！）
 * role: {
            panduan: "level||name",
            level: "!=4", name: ["user"]
        },                        权限  如果需要区分权限必写，并且为对象格式
 * meta : {
    title: 'title'               侧边栏和面包屑中显示的名称（推荐设置）
    icon: 'svg-name'/'el-icon-x' 侧边栏中显示的图标
    noCache: true                如果设置为true，则不会缓存页面（默认为false）
    breadcrumb: false            如果设置为false，则该项将隐藏在面包屑中（默认为true）
    activeMenu: '/example/list'  如果设置路径，侧边栏将突出显示您设置的路径
    placement: "userBox"        菜单位置，可选值有"userBox"（仅在用户中心显示）
  }
 */

var router = [
    {/* 首页 */
        path: '/',
        component: Layout,
        redirect: '/home',
        meta: { title: '首页' },
        children: [
            {
                path: 'home',
                component: () => import('@/views/Home/HomeView'),
                name: 'Dashboard',
                meta: { title: '首页' }
            },
        ]
    },
    {/* 首页 */
        path: '/information',
        component: Layout,
        redirect: '/information/myInfo',
        meta: { title: '个人中心' },
        hidden: true,
        children: [
            {
                path: 'myInfo',
                component: () => import('@/views/myPersons/myInfo'),
                name: 'information',
                meta: { title: '个人中心' }
            },
            {
                path: 'myCollection',
                component: () => import('@/views/myPersons/myCollection'),
                name: 'myCollection',
                meta: { title: '我的收藏' }
            },
        ]
    },

    {
        path: '/ProjectCenter',
        component: Layout,
        redirect: '/ProjectCenter/MyProject',
        meta: { title: '项目中心', },
        children: [
            {
                path: 'MyProject',
                component: () => import('@/views/project/myproject'),
                name: 'MyProject',
                meta: { title: '我的项目', noCache: true }
            },
            {
                path: 'afterSales/:pj_type_id',
                hidden: true,
                component: () => import('@/views/project/afterSales'),
                name: 'afterSales',
                meta: { title: '售后工具', parent: '/ProjectCenter/MyProject' }
            },
            {
                path: 'MyMission',
                component: () => import('@/views/project/mymission'),
                name: 'MyMission',
                meta: { title: '我的任务', noCache: true }
            },
            {
                path: 'lookMission',
                hidden: true,
                component: () => import('@/views/project/lookmission'),
                name: 'lookMission',
                meta: { title: '查看任务', parent: '/ProjectCenter/MyMission' }
            },
        ]
    },
    {
        path: '/cloudServices',
        component: Layout,
        redirect: '/cloudServices/cloudProcess',

        meta: { title: '科研云服务' },
        children: [
            {
                path: 'cloudProcess',
                component: () => import('@/views/cloudServices/cloudProcess'),
                name: 'cloudProcess',
                meta: { title: '云流程', noCache: true },
                role: {
                    panduan: "level",
                    level: "!=4"
                },
            },
            {
                path: 'cloudProcessDetail/:id',
                name: 'cloudProcessDetail',
                hidden: true,
                component: () => import('@/views/cloudServices/cloudProcessChilder'),
                meta: { title: 'ziye', parent: '/cloudServices/cloudProcess' },
            },
            {
                path: 'cloudTools',
                component: () => import('@/views/cloudServices/cloudTools'),
                name: 'cloudTools',
                meta: { title: '云工具', noCache: true }
            },
            {
                path: 'cloudToolsDetail/:id',
                name: 'cloudToolsDetail',
                hidden: true,
                component: () => import('@/views/cloudServices/cloudToolsChilder'),
                meta: { title: 'ziye', parent: '/cloudServices/cloudTools' },
            },
            {
                path: 'cloudDraw',
                component: () => import('@/views/cloudServices/cloudDraw'),
                name: 'cloudDraw',
                meta: { title: '绘图工具', noCache: true }
            },
            {
                path: 'cloudDrawDetail/:id',
                name: 'cloudDrawDetail',
                hidden: true,
                component: () => import('@/views/cloudServices/cloudDrawChilder'),
                meta: { title: 'ziye', parent: '/cloudServices/cloudDraw' },
            },

        ]
    },
    {
        path: '/yuzhong',
        component: Layout,
        redirect: '/yuzhong',
        role: {
            panduan: "level&&username",
            fangshi: { username: "indexOf" },//includes,indexOf
            level: "!=4", username: ["smartbreeding_temp016", "136058626"]
        },
        meta: { title: '育种工具' },
        children: [
            {
                path: '/yuzhong/:id',
                component: () => import('@/views/yuzhong/yuzhong'),
                name: 'yuzhong',
                meta: { title: '育种工具' }
            },
        ]
    },
    {
        path: '/shujuku',
        component: Layout,
        redirect: 'https://sgd.smartgenomics.net/#/home',
        // role: {
        //     panduan: "level||name",
        //     level: "!=4", name: ["user"]
        // },
        meta: { title: '数据库' },
        children: [
            {
                path: 'https://germ.smartgenomics.net/#/',
                meta: { title: '种质资源(植物)' }
            },
            {
                path: 'https://grd.smartgenomics.net/#/',
                meta: { title: '种质资源(动物)' }
            },
            {
                path: 'https://sgd.smartgenomics.net/#/home',
                meta: { title: '单基因组' }
            },
            {
                path: 'https://pangd.smartgenomics.net/#/home',
                meta: { title: '泛基因组' }
            },
            {
                path: 'https://rgd.smartgenomics.net/#/home',
                meta: { title: '重测序' }
            },
            {
                path: 'https://omics.smartgenomics.net/#/home',
                meta: { title: '多组学' }
            },
        ]
    },
    {
        path: '/course',
        component: Layout,
        redirect: '/course/courseVideo',
        alwaysShow: true,
        // role: {
        //     panduan: "level||name",
        //     level: "!=4", name: ["user"]
        // },
        meta: { title: '极智云课程' },
        children: [
            {
                path: 'courseVideo',
                component: () => import('@/views/course/courseVideo'),
                name: 'courseVideo',
                meta: { title: '视频' }
            },
        ]
    },
    {
        path: '/introduce',
        component: Layout,
        redirect: '/introduce/platform',
        alwaysShow: true,
        // role: {
        //     panduan: "level||name",
        //     level: "!=4", name: ["user"]
        // },
        meta: { title: '极智云介绍' },
        children: [
            {
                path: 'platform',
                component: () => import('@/views/introduce/platform'),
                name: 'platform',
                meta: { title: '平台介绍' }
            },
            {
                path: 'method',
                component: () => import('@/views/introduce/method'),
                name: 'method',
                meta: { title: '使用方法' }
            },
            {
                path: 'FAQ',
                component: () => import('@/views/introduce/FAQ'),
                name: 'FAQ',
                meta: { title: 'FAQ' }
            },
        ]
    },
]
export default router