<template>
  <div class="pageBox">
    <el-header class="headerBox">
      <logo class="logo" />
      <top-nav class="topNav" v-if="$globalVariable.menuDirection == 'top'" />
      <user-box class="userBox" />
    </el-header>
    <div class="root">
      <div class="pageMain">
        <keep-alive>
          <router-view :key="key" v-if="$route.meta.noCache" :ref="key" />
        </keep-alive>
        <router-view :key="key" v-if="!$route.meta.noCache" />
        <div
          class="pageFooter"
          :class="{ 'pageFooter-fixed': $route.path !== '/home' }">
          <footer-Box></footer-Box>
        </div>
      </div>

      <!-- <el-main class="pageMain">
        <keep-alive>
          <router-view :key="key" v-if="$route.meta.noCache" />
        </keep-alive>
        <router-view :key="key" v-if="!$route.meta.noCache" />
        <el-footer class="pageFooter">
          <footer-Box></footer-Box>
        </el-footer>
      </el-main> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Layout",
  props: {
    //传值
  },
  data() {
    return {};
  },
  activated() {
    // 狗子触发顺序最后时执行，当再次进入（前进或者后退）时，只触发activated
  },
  components: {
    //引入模块
    topNav: () => import("@/components/Navbar/topNav"),
    logo: () => import("@/components/logo/index"),
    userBox: () => import("@/components/userBox/index"),
    footerBox: () => import("@/components/footerBox/index"),
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
    $route: {
      handler(newVal, oldVal) {
        // 路由发生变化时执行
        this.$store.getters["pageRouter"].map((item) => {
          if (newVal.meta.parent) {
            if (item !== newVal.meta.parent) {
              this.$refs[item].$destroy(); // 销毁组件实例
              this.$store.commit("pageRouter/removeCachedPage", item);
            }
          } else if (item != newVal.path) {
            if (this.$refs[item]) {
              this.$refs[item].$destroy(); // 销毁组件实例
            }
            this.$store.commit("pageRouter/removeCachedPage", item);
          }
        });
        // this.$store.commit("pageRouter/addCachedPage", to.path);
      },
      deep: true,
    },
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    key() {
      return this.$route.path;
    },
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
  },
  methods: {
    // 组件的方法
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style lang="scss" scoped>
.pageBox {
  ::v-deep .el-footer {
    // position: absolute;
    height: fit-content !important;
  }

  ::v-deep .el-header {
    position: fixed;
  }
  .headerBox {
    position: fixed;
    background-color: $TopmenuBg;
    // padding-left: $TopPadding;
    // padding-right: $TopPadding;
    height: $TopHeight !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: pxttrem(50);
    width: 100%;
    z-index: 999;
    box-shadow: 0 pxttrem(1) pxttrem(6) pxttrem(1) rgb(0 0 0 / 6%);
    .logo {
      height: 100%;
      padding: pxttrem(10) 0;
      box-sizing: border-box;
    }
    .topNav {
      flex: 1;
    }
  }
  .root {
    padding-top: $TopHeight;
    position: relative;
    height: calc(100vh - $TopHeight);
    .sideBar {
      width: $sideBarWidth !important;
    }
    .pageMain {
      height: calc(100vh - $main-height);
      padding: 0;
      position: relative;
    }
    .pageFooter {
      position: relative;
      z-index: 999;
      // bottom: 0;
      // width: 100%;
      padding: 0;
    }
    .pageFooter-fixed {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
}
</style>
