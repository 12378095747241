<template>
  <div style="width: 100%">
    <el-select
      size="mini"
      ref="select"
      :value="value"
      @change="changeSelect"
      multiple
      clearable
      :placeholder="$t(placeholder)"
      style="width: 100%"
      class="diyAllSelect">
      <el-button
        type="text"
        :style="{
          color: selectAll ? '#409EFF' : '#606266',
          fontWeight: selectAll ? '700' : '',
        }"
        class="selectAllBtn"
        @click="
          changeSelect(
            selectAll ? [...selectValue] : ['selectAll', ...selectValue]
          )
        ">
        <span style="float: left">{{ $t("全选") }}</span
        ><span v-if="selectAll" class="duihao"></span>
      </el-button>
      <el-option
        v-for="item in newoptions"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "diySeLect",
  props: {
    //传值
    value: {
      type: Array | String,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
  },
  data() {
    return {
      selectValue: this.value,
      selectAll: false, // 用于标识是否全选--默认不全选
    };
  },
  components: {
    //引入模块
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    newoptions() {
      var arr = [];
      this.options.map((item) => {
        if (typeof item == "string") {
          arr.push({ label: item, value: item });
        } else {
          arr.push(item);
        }
      });
      return arr;
    },
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    // const select=this.$refs.elSel
    // for(const key in select){
    //     this[key]=select[key]
    // }
  },
  activated: function () {
    // 狗子触发顺序最后时执行，当再次进入（前进或者后退）时，只触发activated
  },
  methods: {
    // 组件的方法
    changeSelect(value) {
      //  selectAll 为true 的时候，就走全选分支，全选后出现的情况就是取消权限
      console.log(value);
      if (this.selectAll) {
        this.selectAll = false;
        if (value.indexOf("selectAll") > -1) {
          this.selectValue = value.filter((p) => p != "selectAll");
        } else {
          this.selectValue = value;
        }
      } else {
        //   是否点击了‘全选’选项
        if (value.indexOf("selectAll") > -1) {
          // 有‘全选’选项，则将‘全部’和其他值放置一块
          const optionsValue = [];
          this.newoptions.forEach((item) => {
            optionsValue.push(item.value);
          });
          this.selectValue = [...optionsValue];
          this.selectAll = true;
        } else {
          // 若是勾选选择的长度和提供的选项长度是一样的，则是 ‘全选’
          if (value.length === this.newoptions.length) {
            const optionsValue = [];
            this.newoptions.forEach((item) => {
              optionsValue.push(item.value);
            });
            this.selectValue = [...optionsValue];
            this.selectAll = true;
          } else {
            //   都是单选
            this.selectValue = value;
          }
        }
      }
      // 真实的勾选值
      const realSelect = this.selectValue.filter((item) => item != "selectAll");
      console.log(realSelect);
      this.$emit("update:value", realSelect);
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style lang="scss" scoped>
.selectAllBtn {
  width: 100%;
  text-align: left;
  padding: pxttrem(10) pxttrem(20);
}
.duihao::after {
  float: right;
  font-family: element-icons;
  content: "\e6da";
  font-size: pxttrem(12);
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
}
.diyAllSelect {
  ::v-deep .el-select__tags {
    max-height: pxttrem(100);
    overflow-y: auto;
  }
}
</style>
