import i18n from '@/i18n/i18n'
import store from '@/store';
import { Message } from 'element-ui';
export default {
    verifyTaskname(taskName, callbackFunction) {
        let reg = /^[a-zA-Z][a-zA-Z0-9-]*$/;
        if (!reg.test(taskName)) {
            callbackFunction("请输入以字母开头，只包含数字、字母、连接符的任务名称！")
        } else {
            callbackFunction()
        }
    },
    verifyname(taskName, callbackFunction) {
        let reg = /^[a-zA-Z][a-zA-Z0-9_]{0,7}$/;
        if (!reg.test(taskName)) {
            callbackFunction("请输入以字母开头，只包含数字、字母、下划线'_'组成！")
        } else {
            callbackFunction()
        }
    },
    isEqual(obj1, obj2) {//对象比较是否完全相等
        // 检查是否为同一引用
        if (obj1 === obj2) {
            return true;
        }

        // 检查类型
        if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
            return false;
        }

        // 获取对象的属性键
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // 比较属性的数量
        if (keys1.length !== keys2.length) {
            return false;
        }

        // 递归比较每个属性
        for (let key of keys1) {
            // 检查是否在另一个对象中
            if (!keys2.includes(key) || !this.isEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    },
    arraysEqual(arr1, arr2) {//数组比较是否完全相等
        // 判断是否同一引用
        if (arr1 === arr2) return true;

        // 判断长度是否相等
        if (arr1.length !== arr2.length) return false;

        // 遍历数组，判断每个元素
        for (let i = 0; i < arr1.length; i++) {
            const item1 = arr1[i];
            const item2 = arr2[i];

            // 判断是否为数组，若是则递归比较
            const areArrays = Array.isArray(item1) && Array.isArray(item2);
            if (areArrays) {
                if (!arraysEqual(item1, item2)) return false;
            } else if (item1 !== item2) { // 直接比较元素
                return false;
            }
        }

        return true; // 所有元素都相等
    },
    i18nt(key) {
        var str = key
        if (!str) {
            return str
        }
        if (key.indexOf("<br>") !== -1) {
            str = key.split("<br>")
        }
        // const unquotedChineseRegex = /[\u4e00-\u9fa5][\u4e00-\u9fa5\u3000-\u303f\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff00-\uffefa-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?”“≥%（）]*[\u4e00-\u9fa5\u3000-\u303f\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff00-\uffef]/g;
        const unquotedChineseRegex = /([\u4e00-\u9fa5][\u4e00-\u9fa5\u3000-\u303f\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff00-\uffefa-zA-Z0-9!@#$%^&*()_+\-=\[\]{};:\\|,.<>\/?”“≥%（）\. ×µⅹ*]*(?:(?!\$\{).)*?)(?=["'`])/g;
        function removeNonChinesePrefix(str) {
            return str.replace(/^[^\u4e00-\u9fa5]+/, '');
        }
        function extractChinese(fileContent) {
            // console.log(fileContent)
            var unquotedMatches = ("\"" + fileContent + "\"").match(unquotedChineseRegex);
            if (fileContent.indexOf("</") != -1) {
                // console.log("has </")
                unquotedMatches = ("\`" + fileContent + "\`").match(unquotedChineseRegex);
            }

            // console.log(unquotedMatches)
            if (fileContent.length == 1) {
                unquotedMatches = [fileContent]
            }
            // console.log(unquotedMatches)
            const allMatches = [];
            if (unquotedMatches) {
                unquotedMatches.forEach((chineseText) => {
                    allMatches.push(chineseText);
                });
            }

            return allMatches;
        }



        var str1 = str;
        var linshLabel = extractChinese(str1);
        if (typeof str1 === "string") {
            var linshLabel = extractChinese(str1);
            linshLabel.map((item) => {
                if (store.state.statusList.statusList.language === 'en') {
                    str1 = str1.replace(item, " " + i18n.t(item));
                } else {

                    str1 = str1.replace(item, i18n.t(item));
                }
            }); // 获取中文翻译
        } else {
            str1 = str.map(itm => {
                var str2 = itm
                var linshLabel = extractChinese(itm);

                linshLabel.map((item) => {
                    if (store.state.statusList.statusList.language === 'en') {
                        str2 = str2.replace(item, " " + i18n.t(item));
                    } else {
                        str2 = str2.replace(item, i18n.t(item));
                    }
                }); // 获取中文翻译
                return str2
            }).join("<br>")

        }
        // const englishLabel = this.$t(`en.${key}`); // 获取英文翻译
        return str1; // 拼接
    },
    generateUUID() {
        // 生成uuid
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },
    themes() {
        return {
            "NPG期刊配色": {
                "type": "all",
                "colors": ["#E54B34", "#4CBAD4", "#009F86", "#3B5387", "#F29A7F", "#8491B3", "#91D1C1", "#DC0000", "#7E6047", "#CCCCCC", "#BC8B83", "#33ADAD", "#347988", "#9F7685", "#C1969A", "#8BB0BB", "#CE8662", "#B04929", "#A59487", "#E3907E", "#D46F5B", "#41B4C1", "#278C87", "#726486", "#DA988C", "#88A0B7", "#B9AC91", "#C63517", "#927A66", "#DBAEA4", "#97A4AB", "#21A69A", "#3A6688", "#C98882", "#A593A7", "#8EC0BE", "#D85935", "#985738", "#B9AFA9", "#E67059", "#E5BFB9", "#B2CED4", "#779F99", "#747A87", "#F2DCD5", "#A7ABB3", "#C1D1CD", "#DCA5A5", "#7E7770", "#CCCCCC", "#E54B34"]
            },
            "NEJM期刊配色": {
                "type": "all",
                "colors": ["#BB3B28", "#0072B4", "#E08626", "#1F854D", "#7876B1", "#6E99AC", "#DC91FF", "#ED4C97", "#905B6E", "#A07C74", "#928A3C", "#587F7F", "#7487AF", "#A897D5", "#E970C9", "#D6435F", "#A86463", "#7C7E95", "#BA9554", "#52826E", "#858BB0", "#99A2C1", "#E39AE4", "#E26E95", "#747291", "#C2916E", "#6E8856", "#758298", "#8198AE", "#CCAAEA", "#EC82BF", "#C96265", "#BB7B72", "#5A93B4", "#E0B383", "#528569", "#9494B1", "#8DA3AC", "#EEC8FF", "#ED9DC2", "#90767F", "#A08E8A", "#928E67", "#6C7F7F", "#929BAF", "#BFB6D5", "#E9ACD9", "#D68D9B", "#A88686", "#898A95", "#BB3B28"]
            },
            "JAMA期刊配色": {
                "type": "all",
                "colors": ["#374D54", "#DF8E44", "#00A0D4", "#B24645", "#79AE97", "#6A6599", "#7F796B", "#8E6E4F", "#A7988E", "#91778A", "#A07F6C", "#748998", "#776E82", "#5C6360", "#655E52", "#C6946A", "#6F8CAF", "#AB6558", "#779C98", "#71698D", "#6E6D65", "#B67E4A", "#7B9DB1", "#A56167", "#919781", "#6F7798", "#7C7376", "#49585A", "#465154", "#DFB792", "#6ABAD4", "#B27C7B", "#93AEA3", "#827F99", "#7F7C75", "#8E7E6F", "#A7A09B", "#91848E", "#A09086", "#869198", "#7D7882", "#606362", "#65625C", "#C6AD98", "#8F9EAF", "#AB8882", "#8A9C9A", "#7F7B8D", "#6E6E6A", "#B69A80", "#374D54"]
            },
            "LANCET期刊配色": {
                "type": "all",
                "colors": ["#00468B", "#EC0000", "#41B43F", "#0099B3", "#925E9F", "#FDAE91", "#AC002A", "#ACB6B6", "#9E3B4F", "#B2811E", "#40A67D", "#6B7EA9", "#C98599", "#D7675A", "#B86E6B", "#6A7BA1", "#6E4D6D", "#D1793E", "#5EAD73", "#6694AE", "#AE80A1", "#EAA392", "#B46264", "#949CAB", "#C65356", "#8D9D4B", "#4D9F9B", "#8A7CA4", "#E3ABA9", "#C26161", "#B69C9A", "#596D96", "#46688B", "#EC7676", "#7BB47A", "#5AA6B3", "#997F9F", "#FDD6C7", "#AC566B", "#B1B6B6", "#9E6D76", "#B29A68", "#73A692", "#8A93A9", "#C9A7B1", "#D79F99", "#B89392", "#868EA1", "#6E5E6E", "#D1A588", "#00468B"]
            },
            "经典配色方案一": {
                "type": "all",
                "colors": ["#0000FF", "#006400", "#FF0000", "#8A2BE2", "#00F5FF", "#458B00", "#FFFF00", "#FF4500", "#00FF7F", "#FF1493", "#8B4789", "#FF6A6A", "#FFB90F", "#00FF00", "#FF7F24", "#008B45", "#FF3030", "#7FFF00", "#4876FF", "#54FF9F", "#FF4040", "#8470FF", "#C0FF3E", "#FF00FF", "#00BFFF", "#9370DB", "#FF6EB4", "#008B45", "#FFD700", "#836FFF", "#8B2323", "#EE82EE", "#1E90FF", "#FF8247", "#A2CD5A", "#32CD32", "#B22222", "#FA8072", "#FF4040", "#00EEEE", "#7CCD7C", "#C0FF3E", "#FF6347", "#7FFFD4", "#90EE90", "#8B658B", "#FF34B3", "#FF83FA", "#FFF68F", "#98F5FF", "#FFCC99"]
            },
            "经典配色方案二": {
                "type": "all",
                "colors": ["#0000FF", "#8A2BE2", "#006400", "#FF0000", "#FF7F24", "#4876FF", "#8B4789", "#458B00", "#FF4500", "#FFB90F", "#8B2323", "#8470FF", "#008B45", "#FF1493", "#FF8247", "#836FFF", "#B22222", "#008B45", "#FF3030", "#FFFF00", "#00BFFF", "#9370DB", "#00FF7F", "#FF6A6A", "#FFD700", "#FF00FF", "#FF4040", "#1E90FF", "#00FF00", "#FA8072", "#EE82EE", "#7FFF00", "#00F5FF", "#8B658B", "#FF34B3", "#FFF68F", "#54FF9F", "#FF6347", "#C0FF3E", "#FF6EB4", "#00EEEE", "#A2CD5A", "#FFCC99", "#32CD32", "#98F5FF", "#7CCD7C", "#FF83FA", "#C0FF3E", "#7FFFD4", "#90EE90", "#FFD5A6"]
            },
            "经典配色方案三": {
                "type": "all",
                "colors": ["#800080", "#D2691E", "#006400", "#2828CD", "#C71585", "#AE5E1A", "#CD0000", "#9400D3", "#A0522D", "#B90000", "#6A5ACD", "#228B22", "#FF8C0A", "#8B4513", "#9932CC", "#FF0000", "#329632", "#3C5A91", "#FFB400", "#00A5FF", "#AD19EC", "#9A7745", "#F56E6E", "#FFE650", "#64CD3C", "#8C8CFF", "#0078FF", "#FFD232", "#6EE5A3", "#834683", "#DB631F", "#EB0000", "#FF9100", "#FF1493", "#52E4DC", "#96F56E", "#0064CD", "#1EA4FF", "#D36EEC", "#CD5C5C", "#FF7F50", "#D151B7", "#32BEBE", "#54BD54", "#7B68EE", "#1E90FF", "#FFC81E", "#B24BE5", "#9E5A5A", "#FFA374", "#60BD89"]
            },
            "经典配色方案四": {
                "type": "all",
                "colors": ["#388E3C", "#F44336", "#0288D1", "#FF9800", "#727272", "#E91E63", "#673AB7", "#8BC34A", "#2196F3", "#D32F2F", "#FFC107", "#BDBDBD", "#F8BBD0", "#CDDC39", "#009688", "#C2185B", "#FFEB3B", "#212121", "#FFCCBC", "#BBDEFB", "#449A48", "#FF4F42", "#0E94DD", "#FFA40C", "#7E7E7E", "#F52A6F", "#7346C3", "#A0D364", "#2DA2FF", "#DF3B3B", "#FFCD13", "#DCDCDC", "#FFC7DC", "#4B5DC1", "#DBE94F", "#0CA294", "#CE2467", "#FFF747", "#2D2D2D", "#FFD8C8", "#C7EAFF", "#50A654", "#FF5B4E", "#1AA0E9", "#FFB018", "#8A8A8A", "#FF367B", "#7F52CF", "#A3DB62", "#39AEFF", "#ED5E5E", "#FFD91F", "#D5D5D5", "#FFD3E8", "#5769CD", "#E5F451", "#18AEA0", "#DA3073", "#FFFF90", "#393939", "#FFE4D4", "#D3F6FF", "#5CB260", "#FF675A", "#26ACF5", "#FFBC26", "#969696", "#FF4287"]
            },
            "经典配色方案五": {
                "type": "all",
                "colors": ["#FF0000", "#0000FF", "#008000", "#FFFF00", "#800080", "#FF4500", "#4169E1", "#FFA500", "#32CD32", "#9400D3", "#FF7F50", "#FFD700", "#00BFFF", "#2E8B57", "#B22222", "#8B0000", "#FF00FF", "#1E90FF", "#CD853F", "#3CB371", "#FF1493", "#6495ED", "#48D1CC", "#F4A460", "#FF6347"]
            },
            "ggplot2默认配色": {
                "type": "all",
                "colors": ["#F8766D", "#BB9D00", "#00B81F", "#00C0B8", "#00A5FF", "#E76BF3", "#FF6C90", "#E7861B", "#95A900", "#00BE6C", "#00BBDB", "#9590FF", "#FC61D5", "#CF9400", "#5BB300", "#00C19C", "#00B0F6", "#CF78FF", "#FF65AE", "#F37B59", "#AFA100", "#00BA42", "#00BFC4", "#529EFF", "#F066EA", "#FC717F", "#C59900", "#39B600", "#00C1AA", "#00ABFD", "#DC71FA", "#FF689F", "#D89000", "#72B000", "#00C08D", "#00B4EF", "#BF80FF", "#FF62BC", "#E08B00", "#85AD00", "#00BF7D", "#00B8E5", "#AC88FF", "#FF61C9", "#ED8141", "#A3A500", "#00BC59", "#00BDD0", "#7997FF", "#F763E0"]
            },
            "AAAS期刊配色": {
                "type": "all",
                "colors": ["#3B4992", "#EE0000", "#008B45", "#631879", "#008280", "#BB0021", "#5F559B", "#A20056", "#808180", "#1B1919"]
            },
            "JCO期刊配色": {
                "type": "all",
                "colors": ["#0073C2", "#EFC000", "#868686", "#CD534C", "#7AA6DC", "#003C67", "#8F7700", "#3B3B3B", "#A73030", "#4A6990"]
            },
            "D3JS配色": {
                "type": "all",
                "colors": ["#1F77B4", "#FF7F0E", "#2CA02C", "#D62728", "#9467BD", "#8C564B", "#E377C2", "#7F7F7F", "#BCBD22", "#17BECF"]
            },
            "Futurama期刊配色": {
                "type": "all",
                "colors": ["#FF6F00", "#C71000", "#008EA0", "#8A4198", "#5A9599", "#FF6348", "#84D7E1", "#FF95A8", "#3D3B25", "#ADE2D0"]
            },
            "GSEA配色": {
                "type": "all",
                "colors": ["#4500AC", "#2600D1", "#6B58EE", "#8787FF", "#C6C0FF", "#D4D4FF", "#FFBFE5", "#FF8888", "#FF707F", "#FF5959"]
            },
            "IGV配色": {
                "type": "all",
                "colors": ["#5050FF", "#CE3D32", "#749B58", "#F0E685", "#466983", "#BA6338", "#5DB1DD", "#802268", "#6BD76B", "#D595A7"]
            },
            "Locus Zoom配色": {
                "type": "all",
                "colors": ["#D43F3A", "#EEA236", "#5CB85C", "#46B8DA", "#357EBD", "#9632B8", "#B8B8B8"]
            },
            "Rick And Morty配色": {
                "type": "all",
                "colors": ["#FAFD7C", "#82491E", "#24325F", "#B7E4F9", "#FB6467", "#526E2D", "#E762D7", "#E89242", "#FAE48B", "#A6EEE6"]
            },
            "The Simpsons配色": {
                "type": "all",
                "colors": ["#FED439", "#709AE1", "#8A9197", "#D2AF81", "#FD7446", "#D5E4A2", "#197EC0", "#F05C3B", "#46732E", "#71D0F5"]
            },
            "Star Trek配色": {
                "type": "all",
                "colors": ["#CC0C00", "#5C88DA", "#84BD00", "#FFCD00", "#7C878E", "#00B5E2", "#00AF66"]
            },
            "Tron Legacy配色": {
                "type": "all",
                "colors": ["#FF410D", "#6EE2FF", "#F7C530", "#95CC5E", "#D0DFE6", "#F79D1E", "#748AA6"]
            },
            "The University Of Chicago配色": {
                "type": "all",
                "colors": ["#800000", "#767676", "#FFA319", "#8A9045", "#155F83", "#C16622", "#8F3931", "#58593F", "#350E20"]
            },
            "UCSC Genome Browser配色": {
                "type": "all",
                "colors": ["#FF0000", "#FF9900", "#FFCC00", "#00FF00", "#6699FF", "#CC33FF", "#99991E", "#999999", "#FF00CC", "#CC0000"]
            },
            "落日余晖": {
                "type": "all",
                "colors": ["#eba823", "#d7b4b1", "#db5b42", "#f01872", "#fc79dc", "#a755c2", "#9ba1bc", "#6069bf", "#2695f0", "#77e6e4", "#20ba8d", "#a6c1b8", "#32c732", "#9ae022", "#F0E68C", "#FA8072"]
            },
            "活力生机": {
                "type": "all",
                "colors": ["#87C847", "#EA6A6A", "#67E0E3", "#97C979", "#FFC900", "#FF4500", "#9FE6B8", "#E062AE", "#E690D1", "#FB8D34", "#75CAC3", "#E6301C", "#FFECA8", "#2B8FE0", "#008000", "#FFFF00"]
            },
            "花海缤纷": {
                "type": "all",
                "colors": ["#058DC7", "#ED561B", "#24CBE5", "#50B432", "#FF7F50", "#eaad25", "#64E572", "#FF9655", "#FFF263", "#2f775c", "#6AF9C4", "#f08080", "#9400D3", "#00FF00", "#228B22", "#FFC0CB"]
            },
            "甜美马卡龙": {
                "type": "all",
                "colors": ["#8dd3c7", "#ffffb3", "#bebada", "#fb8072", "#80b1d3", "#fdb462", "#b3de69", "#fccde5", "#d9d9d9", "#bc80bd", "#ccebc5", "#ffed6f", "#00FFFF", "#F08080", "#87CEFA", "#7FFF00"],
                "owner": ["stackbar", "pie", "venn", "box"]
            },
            "清新自然": {
                "type": "all",
                "colors": ["#37A2DA", "#E690D1", "#FFDB5C", "#67E0E3", "#9FE6B8", "#FF9F7F", "#FB7293", "#E062AE", "#32C5E9", "#E7BCF3", "#9D96F5", "#8378EA", "#96BFFF", "#E3C6A1", "#00FF7F", "#7CFC00"],
                "owner": ["stackbar", "pie", "venn", "box"]
            },
            "经典深蓝": {
                "type": "all",
                "colors": ["#2A75C9", "#2FAFDE", "#198FB3", "#00CCAD", "#019D96", "#88C947", "#97C979", "#C99E00", "#C9BA89", "#C9A469", "#C96F00", "#C9412C", "#FF4138", "#B3760c", "#FFD700", "#FFFF00"],
                "owner": ["stackbar", "pie", "venn", "box"]
            }
        }
    },
    colorThemes(key) {
        console.log(key)
        var themes = this.themes()
        return themes[key].colors
    },
    detectDataType(data) {//检测数据类型
        if (data instanceof FormData) {
            return "FormData";
        } else if (typeof data === "object") {
            return "JSON";
        } else {
            return "Unknown data type";
        }
    },
    cloneFormData(formData) {//克隆formData
        const newFormData = new FormData();
        for (const [key, value] of formData.entries()) {
            newFormData.append(key, value);
        }
        return newFormData;
    },

    // 格式化表格数据，并用下划线替换特殊字符
    formTableData(data, callbackFunction) {
        console.log(data)
        var heard = []
        if (data.data) {
            var Data = data.data.map(item => {
                for (var key in item) {
                    var str = key
                    str = str.replace(/\./g, "_").replace(/\^/g, "_").replace(/\s/g, "_")
                    // if (key.indexOf(".") != -1) {
                    // 	var str = key.replace(/\./g, "_")
                    // 	item[str] = item[key]
                    // 	delete item[key]
                    // }
                    // if (key.indexOf("^") != -1) {
                    // 	var str = key.replace(/\^/g, "_")
                    // 	item[str] = item[key]
                    // 	delete item[key]
                    // }
                    // if (key.indexOf(" ") != -1) {
                    // 	var str = key.replace(/\s/g, "_")
                    item[str] = item[key]
                    // delete item[key]
                    // }
                }
                return item
            })
        }
        if (data.table) {
            var Data = data.table.map(item => {
                for (var key in item) {
                    var str = key
                    str = str.replace(/\./g, "_").replace(/\^/g, "_").replace(/\s/g, "_")
                    // if (key.indexOf(".") != -1) {
                    // 	var str = key.replace(/\./g, "_")
                    // 	item[str] = item[key]
                    // 	delete item[key]
                    // }
                    // if (key.indexOf("^") != -1) {
                    // 	var str = key.replace(/\^/g, "_")
                    // 	item[str] = item[key]
                    // 	delete item[key]
                    // }
                    // if (key.indexOf(" ") != -1) {
                    // 	var str = key.replace(/\s/g, "_")
                    item[str] = item[key]
                    // delete item[key]
                    // }
                }
                return item
            })
        }

        // if(id==97){
        // 	var heard = data.columns.map((item,index) => {
        // 		var json={
        // 			width: 'auto',
        // 			prop: item.replace(/\./g, "_").replace(/\^/g, "_").replace(/\s/g, "_"),
        // 			label: "name"+index
        // 		}
        // 		switch(index){
        // 			case 0:
        // 				json.label="Query id"
        // 			break;
        // 			case 1:
        // 				json.label="Subject id"
        // 			break;
        // 			case 2:
        // 				json.label="% identity"
        // 			break;
        // 			case 3:
        // 				json.label="Query id"
        // 			break;
        // 			case 4:
        // 				json.label="alignment length"
        // 			break;
        // 			case 5:
        // 				json.label="mismatches"
        // 			break;
        // 			case 6:
        // 				json.label="gap openings"
        // 			break;
        // 			case 7:
        // 				json.label="q. start"
        // 			break;
        // 			case 8:
        // 				json.label="q. end"
        // 			break;
        // 			case 9:
        // 				json.label="s. start"
        // 			break;
        // 			case 10:
        // 				json.label="s. end"
        // 			break;
        // 			case 10:
        // 				json.label="s. end"
        // 			break;
        // 		}
        // 		return json
        // 	})
        // }else{
        if (data.columns) {
            var heard = data.columns.map(item => {
                return {
                    width: 'auto',
                    prop: item.replace(/\./g, "_").replace(/\^/g, "_").replace(/\s/g, "_"),
                    label: item
                }
            })
        }
        if (data.head) {
            var heard = data.head.map(item => {
                return {
                    width: 'auto',
                    prop: item.replace(/\./g, "_").replace(/\^/g, "_").replace(/\s/g, "_"),
                    label: item
                }
            })
        }

        // }

        callbackFunction(Data, heard)
    },

    successMsg(response) {
        Message({
            showClose: false,
            message: response && response.msg ? response.msg : response,
            type: "success",
            offset: "100",
        })
    },
    errorMsg(response) {
        Message({
            showClose: false,
            message: response && response.msg ? response.msg : response,
            type: "error",
            offset: "100",
        })
    },
}