import request from '@/utils/request'
import requestQd from '@/utils/requestQd'
// 获取项目列表
export function bigFileUpload(data, config, requestUrl) {
    if (!requestUrl) {
        requestUrl = "天津"
    }
    if (requestUrl == "天津") {
        return request({
            url: "/bigFileUpload",
            headers: config.headers,
            method: 'post',
            data
        })
    } else if (requestUrl == "青岛") {
        return requestQd({
            url: "/bigFileUpload",
            headers: config.headers,
            method: 'post',
            data
        })
    }

}